import { onMounted, ref, watch } from "@vue/composition-api";
import footerDataTables from "@/fake-db/footerDataTables.json";
import { api } from "@/services/api";
import { removeComma } from "@/services/filters"
import moment from "moment/moment";
import { useRouter } from "@core/utils";

export default function useProductBalance() {

    const textSearch = ref('')
    const XLSX = require('xlsx')
    const headers = ref([
        {
            text: '#',
            value: 'number',
            width: '70',
        },
        {
            text: 'คลังสินค้า',
            value: 'store_name',
            width: '120',
        },
        {
            text: 'วันที่',
            value: 'create_date',
            width: '120',
        },
        {
            text: 'รหัสสินค้า',
            value: 'product_code',
            width: '120',
        },
        {
            text: 'ชื่อสินค้า',
            value: 'product_name',
            width: '200',
        },
        {
            text: 'นำเข้า',
            value: 'import',
            width: '130',
            align: 'end',
        },
        {
            text: 'ขายออก',
            value: 'sell',
            width: '130',
            align: 'end',
        },
        {
            text: 'คงเหลือ',
            value: 'balance',
            width: '130',
            align: 'end',
        },

    ])
    const itemsPerPage = ref(-1)
    const status = ref('1')
    const dataList = ref([])
    const loading = ref(false)
    const isUpdateStatus = ref(false)
    const dataEdit = ref({})
    const footer = ref(footerDataTables)
    const isEdit = ref(false)
    const isAdd = ref(false)
    const isShowDetail = ref(false)
    const storeList = ref([])
    const storeSelection = ref('')
    const exportLoading = ref(false)
    const moment = require('moment')
    const start = ref(moment(new Date()).format('YYYY-MM-DD'))
    const end = ref(moment(new Date()).format('YYYY-MM-DD'))
    const totalBalance = ref('')
    const totalImport = ref('')
    const totalSell = ref('')
    const { route } = useRouter()


    const getProducts = () => {
        loading.value = true
        api.get({
            path: '/reportProductMovement',
            param: `?store_id=${ storeSelection.value }&search=${ textSearch.value }&start=${ start.value }&end=${ end.value }&currency_id=${ localStorage.getItem('currencyId') }`,
        }).then(({ data, balanceTotal, importTotal, sellTotal }) => {
            totalBalance.value = balanceTotal
            totalImport.value = importTotal
            totalSell.value = sellTotal
            mapData(data)
            loading.value = false
        }).catch(error => {
            console.log('error :', error)
            loading.value = false
        })
    }

    const getStores = () => {
        api.get({
            path: '/manager/stores',
            param: `?search=${ textSearch.value }&active=1`,
        }).then(({ data }) => {
            storeList.value = data
            storeList.value.unshift({ store_id: '', store_name: 'ทั้งหมด' })
            storeSelection.value = data[0].store_id
            getProducts()
        }).catch(error => {
            console.log('error :', error)
        })
    }

    const mapData = (data) => {
        dataList.value = data.map((item, index) => {
            return {
                number: index + 1,
                ...item,
            }
        })
        dataList.value.push({
            product_name: 'รวม',
            'import': totalImport.value,
            sell: totalSell.value,
            balance: totalBalance.value,
        })

    }

    const exportExcel = async () => {
        exportLoading.value = true
        const fileName = `รายงานเคลือนไหวสินค้า ตั้งแต่วันที่ ${ start.value } ถึง วันที่${ end.value }.xlsx`
        const Heading = [
            [`รายงานเคลือนไหวสินค้า ตั้งแต่วันที่ ${ start.value } ถึง วันที่${ end.value }`],
            [
                '#',
                `คลังสินค้า`,
                `วันที่`,
                `รหัสสินค้า`,
                'ชื่อสินค้า',
                'นำเข้า',
                `ขายออก`,
                'คงเหลือ',
            ],
        ]

        const ws = XLSX.utils.aoa_to_sheet(Heading)

        const dataExport = await removeComma(JSON.parse(JSON.stringify(dataList.value)))
        XLSX.utils.sheet_add_json(ws, dataExport, {
            header: [
                'number',
                'store_name',
                'create_date',
                'product_code',
                'product_name',
                'import',
                'sell',
                'balance',
            ],
            skipHeader: true,
            origin: -1,
        })
        const wb = XLSX.utils.book_new()
        const merge = [
            { s: { r: 0, c: 0 }, e: { r: 0, c: 7 } },
        ]
        const wscols = [
            { wch: 6 },
            { wch: 10 },
            { wch: 26 },
            { wch: 15 },
            { wch: 15 },
            { wch: 15 },
            { wch: 15 },
            { wch: 15 },
        ]
        ws['!merges'] = merge
        ws['!cols'] = wscols
        XLSX.utils.book_append_sheet(
            wb,
            ws,
            `รายงานเคลือนไหวสินค้า`,
        )

        XLSX.writeFile(wb, fileName)
        setTimeout(() => {
            exportLoading.value = false
        }, 1500)
    }

    onMounted(() => {
        getStores()
    })

    const addPayload = (dateStart, dateEnd) => {
        start.value = dateStart
        end.value = dateEnd
        getProducts()
    }


    return {
        textSearch,
        headers,
        itemsPerPage,
        isAdd,
        isShowDetail,
        isEdit,
        dataEdit,
        footer,
        isUpdateStatus,
        status,
        dataList,
        loading,
        storeList,
        storeSelection,
        exportLoading,
        exportExcel,
        addPayload,
        getProducts,
    }
}