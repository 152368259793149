var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',[_c('v-card-title',{staticClass:"px-2"},[_c('span',[_vm._v("รายงานความเคลื่นไหวสินค้า")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","loading":_vm.exportLoading,"disabled":_vm.loading},on:{"click":_vm.exportExcel}},[_vm._v("ออกรายงาน ")])],1),_c('DateFilters',{attrs:{"default-status":1},on:{"onSendDate":_vm.addPayload}}),_c('v-row',{staticClass:"px-2 pb-2"},[_c('v-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('v-text-field',{attrs:{"filled":"","label":"ค้นหาสินค้า","dense":"","hide-details":"","placeholder":"คำค้นหา"},on:{"input":_vm.getProducts},model:{value:(_vm.textSearch),callback:function ($$v) {_vm.textSearch=$$v},expression:"textSearch"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('v-autocomplete',{attrs:{"filled":"","items":_vm.storeList,"label":"เลือกคลังสินค้า","item-value":"store_id","item-text":"store_name","dense":"","hide-details":"","placeholder":"คำค้นหา"},on:{"change":_vm.getProducts},model:{value:(_vm.storeSelection),callback:function ($$v) {_vm.storeSelection=$$v},expression:"storeSelection"}})],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.dataList,"items-per-page":_vm.itemsPerPage,"loading":_vm.loading,"no-data-text":"ไม่มีข้อมูล !","hide-default-footer":"","loading-text":"กำลังโหลดข้อมูล...","footer-props":_vm.footer,"no-results-text":"ไม่มีข้อมูล !"},scopedSlots:_vm._u([{key:"item.store_name",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(item.store_name)+" ")])]}},{key:"item.create_date",fn:function(ref){
var item = ref.item;
return [(item.product_code)?_c('span',[_vm._v(" "+_vm._s(_vm._f("sumdate")(item.create_date))+" ")]):_vm._e()]}},{key:"item.product_name",fn:function(ref){
var item = ref.item;
return [_c('span',{class:item.product_name ==='รวม'?'primary--text font-weight-bold':''},[_vm._v(" "+_vm._s(item.product_name))])]}},{key:"item.import",fn:function(ref){
var item = ref.item;
return [_c('span',{class:item.product_name ==='รวม'?'primary--text font-weight-bold':''},[_vm._v(" "+_vm._s(item.import)+" ")])]}},{key:"item.sell",fn:function(ref){
var item = ref.item;
return [_c('span',{class:item.product_name ==='รวม'?'primary--text font-weight-bold':''},[_vm._v(" "+_vm._s(item.sell))])]}},{key:"item.balance",fn:function(ref){
var item = ref.item;
return [_c('span',{class:item.product_name ==='รวม'?'primary--text font-weight-bold':''},[_vm._v(" "+_vm._s(item.balance))])]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }