<template>
    <div>
        <v-card>
            <v-card-title class="px-2">
                <span>รายงานความเคลื่นไหวสินค้า</span>
                <v-spacer></v-spacer>
                <v-btn color="primary" @click="exportExcel" :loading="exportLoading" :disabled="loading">ออกรายงาน
                </v-btn>
            </v-card-title>
            <DateFilters :default-status="1" @onSendDate='addPayload'/>
            <v-row class='px-2 pb-2'>
                <v-col cols='12' md='6' lg='4'>
                    <v-text-field v-model='textSearch'
                                  filled
                                  @input='getProducts'
                                  label='ค้นหาสินค้า'
                                  dense
                                  hide-details
                                  placeholder='คำค้นหา'>

                    </v-text-field>
                </v-col>
                <v-col cols='12' md='6' lg='4'>
                    <v-autocomplete
                            v-model='storeSelection'
                            filled
                            :items='storeList'
                            label='เลือกคลังสินค้า'
                            item-value="store_id"
                            item-text="store_name"
                            @change="getProducts"
                            dense
                            hide-details
                            placeholder='คำค้นหา'>
                    </v-autocomplete>
                </v-col>

            </v-row>
            <v-data-table
                    :headers='headers'
                    :items='dataList'
                    :items-per-page='itemsPerPage'
                    :loading='loading'
                    no-data-text='ไม่มีข้อมูล !'
                    hide-default-footer
                    loading-text='กำลังโหลดข้อมูล...'
                    :footer-props='footer'
                    no-results-text='ไม่มีข้อมูล !'
                    class='elevation-1'
            >
                <template #[`item.store_name`]='{ item }'>
          <span class="font-weight-bold">
            {{ item.store_name }}
          </span>
                </template>
                <template #[`item.create_date`]='{ item }'>
          <span v-if="item.product_code">
            {{ item.create_date | sumdate }}
          </span>
                </template>
                <template #[`item.product_name`]='{ item }'>
          <span :class="item.product_name ==='รวม'?'primary--text font-weight-bold':''">
            {{
                  item.product_name
              }}</span>
                </template>
                <template #[`item.import`]='{ item }'>
          <span :class="item.product_name ==='รวม'?'primary--text font-weight-bold':''">
            {{
                  item.import
              }}
          </span>
                </template>
                <template #[`item.sell`]='{ item }'>
          <span :class="item.product_name ==='รวม'?'primary--text font-weight-bold':''">
            {{
                  item.sell
              }}</span>
                </template>
                <template #[`item.balance`]='{ item }'>
          <span :class="item.product_name ==='รวม'?'primary--text font-weight-bold':''">
            {{
                  item.balance
              }}</span>
                </template>
            </v-data-table>
        </v-card>
    </div>
</template>

<script>
import useProductMovement from "@/views/report/useProductMovement.js";
import DateFilters from "@/components/DateFilters";
import { sumdate } from "@/services/filters";

export default {
    props: {},
    components: { DateFilters },
    filters: {
        sumdate
    },
    setup(_, { root, emit }) {
        return { ...useProductMovement() }
    },
}
</script>

<style scoped>

</style>
