<template>
    <v-row class="px-2">
        <v-col
                cols="12"
                md="6"
                lg="3"
        >
            <v-select
                    v-model="select_range"
                    :items="data_range_select"
                    label="เลือกช่วงเวลา"
                    item-text="th"
                    item-value="value"
                    hide-details
                    filled
            ></v-select>
        </v-col>
        <v-col
                cols="12"
                md="6"
                lg="3"
        >
            <v-select
                    v-model="optionsSelected"
                    :items="select_range==2?dataMonthList:select_range==3?dataYearList:[]"
                    :label="select_range==1?'วันนี้':select_range==2?'เลือกเดือน':select_range==3?`เลือกปี`:select_range==4?'กำหนดเอง': 'ทั้งหมด'"
                    :disabled="select_range==1||select_range==4||select_range==5"
                    hide-details
                    filled
                    item-value="value"
                    item-text="th"
            ></v-select>
        </v-col>
        <v-col
                cols="12"
                md="6"
                lg="3"
        >
            <v-dialog
                    ref="dateStart"
                    v-model.trim="isShowDate1"
                    :return-value.sync="date_start"
                    width="290px"
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                            v-model.trim="date_start"
                            label="วันที่เริ่มต้น"
                            hide-details
                            filled
                            :disabled="select_range!=4 && select_range!=1"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                    >
                    </v-text-field>
                </template>
                <v-date-picker
                        v-model.trim="date_start"
                        locale="th"
                        scrollable
                >
                    <v-spacer></v-spacer>
                    <v-btn
                            text
                            color="primary"
                            @click="$refs.dateStart.save(date_start)"
                    >
                        ยืนยัน
                    </v-btn>
                    <v-btn
                            text
                            color="secondary"
                            @click="isShowDate1 = false"
                    >
                        ยกเลิก
                    </v-btn>

                    <v-spacer></v-spacer>
                </v-date-picker>
            </v-dialog>
        </v-col>
        <v-col
                cols="12"
                md="6"
                lg="3"
        >
            <v-dialog
                    ref="dateEnd"
                    v-model.trim="isShowDate2"
                    :return-value.sync="date_end"
                    width="290px"
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                            v-model.trim="date_end"
                            label="วันที่สิ้นสุด"
                            readonly
                            hide-details
                            filled
                            :disabled="select_range!=4"
                            v-bind="attrs"
                            v-on="on"
                    >
                    </v-text-field>
                </template>
                <v-date-picker
                        v-model.trim="date_end"
                        locale="th"
                        scrollable
                >
                    <v-spacer></v-spacer>

                    <v-btn
                            text
                            color="primary"
                            @click="$refs.dateEnd.save(date_end)"
                    >
                        ยืนยัน
                    </v-btn>
                    <v-btn
                            text
                            color="secondary"
                            @click="isShowDate2 = false"
                    >
                        ยกเลิก
                    </v-btn>
                    <v-spacer></v-spacer>
                </v-date-picker>
            </v-dialog>
        </v-col>
    </v-row>
</template>

<script>
import { onMounted, ref, watch } from '@vue/composition-api'
import monthList from '@/fake-db/monthList.json'
import rangeSelete from '@/fake-db/rangeSelete.json'
import { api } from "@/services/api"
import { useRouter } from "@core/utils";

export default {
    props: {
        defaultStatus: {
            type: Number,
            default: 2,
        },
    },
    setup(props, { emit }) {
        const moment = require('moment')
        const data_range_select = ref(rangeSelete.data)
        const select_range = ref(props.defaultStatus)
        const dataMonthList = ref(monthList.data)
        const optionsSelected = ref('')
        const date_start = ref(moment(new Date()).format('YYYY-MM-DD'))
        const date_end = ref(moment(new Date()).format('YYYY-MM-DD'))
        const isShowDate1 = ref(false)
        const isShowDate2 = ref(false)
        const dataYearList = ref([])
        const dateStartShop = ref('')
        const { route } = useRouter()

        onMounted(async () => {
            if (route.value.query.date) {
                date_start.value = route.value.query.date
            } else {
                api.get({
                    path: '/shop'
                }).then(({ data }) => {
                    dateStartShop.value = data.shop_create
                    const dt = new Date()
                    const month = dt.getMonth()
                    let createShopYear = +data.shop_create.substring(4, 0) - 2
                    const currentYear = new Date().getFullYear()
                    for (let i = createShopYear; i <= currentYear; i++) {
                        dataYearList.value.push({
                            en: createShopYear,
                            th: createShopYear + 543,
                            value: createShopYear,
                        })
                        createShopYear += 1
                    }
                    optionsSelected.value = monthList.data[month].value
                    starterFunction(props.defaultStatus)
                })
            }
        })


        const starterFunction = newVal => {
            const dt = new Date()
            const month = dt.getMonth()
            if (newVal == 1) {
                optionsSelected.value = ''
                date_start.value = moment(new Date()).format('YYYY-MM-DD')
                date_end.value = ''
            } else if (newVal == 2) {
                optionsSelected.value = monthList.data[month].value
                date_start.value = `${ dataYearList.value[dataYearList.value.length - 1].value }-${ optionsSelected.value }-01`
                date_end.value = moment(new Date()).format('YYYY-MM-DD')
            } else if (newVal == 3) {
                optionsSelected.value = dataYearList.value[dataYearList.value.length - 1].value
                date_start.value = `${ optionsSelected.value }-01-01`
                date_end.value = moment(new Date()).format('YYYY-MM-DD')
            } else if (newVal == 4) {
                optionsSelected.value = ''
                date_start.value = moment(new Date()).format('YYYY-MM-DD')
                date_end.value = moment(new Date()).format('YYYY-MM-DD')
            } else if (newVal == 5) {
                optionsSelected.value = ''

                const fakeDate = `${ dateStartShop.value.substring(4, 0) - 2 }-01-01`
                date_start.value = fakeDate

                date_end.value = moment(new Date()).format('YYYY-MM-DD')
            }
        }

        const secondFunction = newVal => {
            if (select_range.value == 2) {
                const daysInMonth = new Date(dataYearList.value[dataYearList.value.length - 1].value, optionsSelected.value, 0).getDate()
                date_start.value = `${ dataYearList.value[dataYearList.value.length - 1].value }-${ optionsSelected.value }-01`
                date_end.value = `${ dataYearList.value[dataYearList.value.length - 1].value }-${ optionsSelected.value }-${ daysInMonth }`
                sendDate()
            }
            if (select_range.value == 3) {
                const currentYear = new Date().getFullYear()
                date_start.value = `${ newVal }-01-01`
                date_end.value = currentYear == optionsSelected.value ? moment(new Date()).format('YYYY-MM-DD') : `${ optionsSelected.value }-12-31`
                sendDate()
            }
        }

        const sendDate = () => {
            emit('onSendDate', date_start.value, date_end.value, select_range.value)
        }

        watch(() => props.defaultStatus, () => {
            select_range.value = props.defaultStatus
        })

        watch([select_range, optionsSelected, date_start, date_end], (newVal, oldVal) => {
            if (newVal[0] != oldVal[0]) {
                starterFunction(newVal[0])
            }
            if (newVal[1] != oldVal[1]) {
                secondFunction(newVal[1])
            }
            if (newVal[2] != oldVal[2]) {
                if (select_range.value == 1) {
                    date_end.value = date_start.value
                    sendDate()
                }
                if (select_range.value == 4 || select_range.value == 5) {
                    sendDate()
                }
            }
            if (newVal[3] != oldVal[3]) {
                if (select_range.value == 4) {
                    sendDate()
                }
            }
        })

        return {
            dataYearList,
            dataMonthList,
            select_range,
            data_range_select,
            optionsSelected,
            date_start,
            date_end,
            isShowDate1,
            isShowDate2,
        }
    },
}
</script>

<style lang="scss" scoped>

</style>
